import React from "react";
import { Form, Collapse, Button, Col, Row, Tag } from "antd";
import { LeftOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
const JobDetails = ({ selectedItem, onBackClick }) => {
  if (!selectedItem) {
    return <p>Chọn một hàng để xem chi tiết.</p>;
  }

  return (
    <Form>
      <Form layout="inline" className="form">
        <Form.Item>
          <span className="firstItem">Job Detail</span>
        </Form.Item>
        <Form.Item>
          <Button onClick={onBackClick}>
            <LeftOutlined />
          </Button>
        </Form.Item>
      </Form>
      <p>Status: <Tag style={{fontSize: "15px"}} color="volcano">{selectedItem.status}</Tag></p>

      {/*Job Detail*/}
      <Collapse accordion defaultActiveKey={["1"]}>
        <Panel header="General Information" key="1">
          <Row gutter={16} className="boderrow">
            <Col span={8}>
              <h3>ID</h3>
              <span>{selectedItem.id}</span>
            </Col>
            <Col span={8}>
              <h3>Service</h3>
              <span>Air-con</span>
            </Col>
            <Col span={8}>
              <h3>Priority</h3>
              <span>Normal</span>
            </Col>
          </Row>
          <Row gutter={16} className="boderrow">
            <Col span={8}>
              <h3>Posted Date Time</h3>
              <span>{selectedItem.posted}</span>
            </Col>
            <Col span={8}>
              <h3>Scheduled Date Time</h3>
              <span>{selectedItem.scheduled}</span>
            </Col>
            <Col span={8}>
              <h3>Indicative Value</h3>
              <span>Fixelist to offer</span>
            </Col>
          </Row>
          <Row gutter={16} className="boderrow">
            <Col span={8}>
              <h3>Customer</h3>
              <span>{selectedItem.customer}</span>
            </Col>
            <Col span={8}>
              <h3>Fixelist</h3>
              <span>{selectedItem.fixelist}</span>
            </Col>
            <Col span={8}>
              <h3>Worker</h3>
              <span></span>
            </Col>
          </Row>
          <Row gutter={16} className="boderrow">
            <Col span={8}>
              <h3>Total Paid Amount</h3>
              <span>$40.00</span>
            </Col>
            <Col span={8}>
              <h3>GST (For Paid Amount)</h3>
              <span>$0</span>
            </Col>
            <Col span={8}>
              <h3>Penalty (Fixelist To Pay)</h3>
              <span>$0</span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <h3>Penalty (Customer To Pay)</h3>
              <span>$21.40</span>
            </Col>
            <Col span={8}>
              <h3>Refund To Customer</h3>
              <span>$20.00</span>
            </Col>
            <Col span={8}>
              <h3>Customer's Address</h3>
              <span>A #4-B Quang Dong Nui thanh Singapore 550000</span>
            </Col>
          </Row>
        </Panel>
        <Panel header="Milestone" key="2">
          Nội dung mục Milestone
        </Panel>
        <Panel header="Offers" key="3">
          Nội dung mục Offers
        </Panel>
        <Panel header="Job Description" key="4">
          Nội dung mục Job Description
        </Panel>
      </Collapse>
    </Form>
  );
};

export default JobDetails;
