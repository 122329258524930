import React, { useState, useEffect } from "react";
import JobDetails from "./JobDetails";
import HeaderItem from "../job/headerContent/HeaderItem";
import { Card, Col, Row, Pagination, Tag } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import Search from "../job/search/Search";

const data = [
  {
    id: "AC#049108",
    status: "CANCELED",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "27/01/2023 11:31 PM",
    scheduled: "05/03/2023 18:31 PM",
    complete: "15/05/2023 06:31 AM",
  },
  {
    id: "PL#049110",
    status: "COMPLETE",
    customer: "cuong",
    fixelist: "Metatechnolody",
    posted: "16/09/2023 12:31 AM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "IT#049109",
    status: "CANCELED",
    customer: "hoai",
    fixelist: "Dbuilder",
    posted: "18/08/2023 11:31 PM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "AC#049109",
    status: "COMPLETE",
    customer: "sadd",
    fixelist: "Dbuilder",
    posted: "23/05/2023 11:31 PM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "PL#049109",
    status: "CANCELED",
    customer: "asdassd",
    fixelist: "MetaTechnology",
    posted: "03/10/2023 11:31 PM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "IT#049109",
    status: "COMPLETE",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "PL#049109",
    status: "COMPLETE",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "IT#049109",
    status: "CANCELED",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "AC#049109",
    status: "CANCELED",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "05/03/2023 18:31 PM",
    complete: "15/05/2023 06:31 AM",
  },
  {
    id: "AC#049109",
    status: "CANCELED",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "05/03/2023 18:31 PM",
    complete: "15/05/2023 06:31 AM",
  },
  {
    id: "AC#049109",
    status: "CANCELED",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "05/03/2023 18:31 PM",
    complete: "15/05/2023 06:31 AM",
  },
];

const itemsPerPage = 8;

const JobContent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDetailVisible, setIsDetailVisible] = useState(false); // Thêm biến trạng thái này

  // Tính toán chỉ mục của mục đầu tiên và cuối cùng trên trang hiện tại
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Cắt danh sách công việc thành các trang
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Xử lý sự kiện khi người dùng chọn một thẻ Card
  const handleCardClick = (item) => {
    setSelectedItem(item);
    setIsDetailVisible(true); // Hiển thị chi tiết và ẩn thẻ Card khi chọn
  };

  // Xử lý sự kiện khi người dùng thay đổi trang
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedItem(null); // Reset selectedItem khi chuyển trang
    setIsDetailVisible(false); // Ẩn chi tiết khi chuyển trang
  };

  const handleBackClick = () => {
    setSelectedItem(null);
    setIsDetailVisible(false);
  };

  const getCardStyle = (status) => {
    return status === "COMPLETE" ? { background: "#c4cfed" } : {};
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let columnCount = 4; // Mặc định 4 cột trên màn hình lớn

  if (windowWidth <= 804 && windowWidth > 500) {
    columnCount = 2; // 2 cột trên màn hình từ 500px đến dưới 800px
  } else if (windowWidth <= 500) {
    columnCount = 1; // 1 cột trên màn hình nhỏ hơn hoặc bằng 500px
  }


  return (
    <div>
      <div className="search">
        <Search/>
      </div>
      {!isDetailVisible && (
        <div className="contentItem">
          <HeaderItem />

          <Row gutter={16}>
            {currentItems.map((item, index) => (
              <Col
                span={24 / columnCount}
                key={index}
                onClick={() => handleCardClick(item)}
              >
                <Card
                  className="custom-card"
                  style={getCardStyle(item.status)}
                  title={item.id}
                  bordered={false}
                >
                  <p>
                    Status: <Tag color="volcano">{item.status}</Tag>
                  </p>
                  <p>Customer: {item.customer}</p>
                  <p>
                    <CalendarOutlined />: {item.posted}
                  </p>
                </Card>
              </Col>
            ))}
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1%",
            }}
          >
            <Pagination
              current={currentPage}
              total={data.length}
              pageSize={itemsPerPage}
              onChange={handlePageChange}
            />
          </div>
        </div>
      )}

      {isDetailVisible && (
        <JobDetails selectedItem={selectedItem} onBackClick={handleBackClick} />
      )}
    </div>
  );
};

export default JobContent;
