import Job from './component/slider/Job';

function App() {
  return (
    <div className="App">
      <Job />
    </div>
  );
}

export default App;
