import React, {useState}from "react";
import { Button, Form, Input, Select, DatePicker } from "antd";

const Search = ({ onSearch }) => {


  return (
    <Form  className="formtole" layout="inline">
      <Form className="firstItem" layout="inline">
        <Form.Item>
          <Input className="input" placeholder="Enter ID" />
        </Form.Item>
        <Form.Item>
          <Select className="input" placeholder="COMPLETE">
            <Select.Option value="1">COMPLETE</Select.Option>
            <Select.Option value="2">CANCELED</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item >
          <Input className="input" placeholder="Enter User" />
        </Form.Item>
        <Form.Item>
          <DatePicker  className="input"/>
        </Form.Item>
      </Form>

      <Form className="form" layout="inline">
        <Form.Item>
          <Button type="primary">Search</Button>
        </Form.Item>
        <Form.Item>
          <Button>EXPAND</Button>
        </Form.Item>
      </Form>
    </Form>
  );
};

export default Search;
