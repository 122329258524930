import React, { useState } from "react";
import {
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  CopyOutlined,
  ShoppingOutlined,
  CreditCardOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  AlignLeftOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, Button, Tooltips, Tooltip } from "antd";
import "./Job.css";
import { Avatar, Badge, Space } from "antd";

import JobContent from "../../pages/job/JobContent";

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("Dashboard", "1", <PieChartOutlined />),
  getItem("Customer", "2", <UserOutlined />),
  getItem("Fixelist", "3", <CopyOutlined />),
  getItem("Job", "4", <ShoppingOutlined />),
  getItem("Payment", "5", <CreditCardOutlined />),
  getItem("Staff Role", "6", <TeamOutlined />),
  getItem("Setting", "set", <SettingOutlined />, [
    getItem("Setting 1", "7"),
    getItem("Setting 2", "8"),
  ]),
  getItem("Support", "cus", <QuestionCircleOutlined />, [
    getItem("Customer Support 1", "9"),
    getItem("Customer Support 2", "10"),
  ]),
];

const Job = () => {
  const [collapsed, setCollapsed] = useState(false);
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();

  const [content, setContent] = useState(<JobContent />);
  const handleMenuItemSelect = ({ key, item }) => {
    // Dựa vào key của mục đã chọn, cập nhật nội dung tương ứng
    switch (key) {
      case "1":
        setContent("Nội dung cho Dashboard.");
        break;
      case "2":
        setContent("Nội dung cho Customer.");
        break;
      case "3":
        setContent("Nội dung cho Fixelist.");
        break;
      case "4":
        setContent(<JobContent />);
        break;
      case "5":
        setContent("Nội dung cho Payment.");
        break;
      case "6":
        setContent("Nội dung cho Staff Role.");
        break;
      case "7":
        setContent("Nội dung cho Setting 1.");
        break;
      case "8":
        setContent("Nội dung cho Setting 2.");
        break;
      case "9":
        setContent("Nội dung cho Customer Support 1.");
        break;
      default:
        setContent("Nội dung cho Customer Support 2.");
        break;
    }
  };

  return (
    <Layout className="layOut">
      <Sider
        className="slider"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="logo">
          <h1>Logo</h1>
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["4"]}
          mode="inline"
          items={items}
          onSelect={handleMenuItemSelect}
          className="menu"
        />
      </Sider>
      <Layout className="layoutContent">
        {/*Header*/}
        <Header className="header">
          <Button className="menu-toggle" style={{ marginLeft: "15px" }}>
            <AlignLeftOutlined />
          </Button>

          <Space size={24} className="avatar">
            <Tooltip placement="topRight" title="Thoát">
              <Badge dot>
                <Avatar shape="square" icon={<UserOutlined />} />
              </Badge>
            </Tooltip>
          </Space>
        </Header>

        {/*Content*/}
        <Content className="content">
          <Breadcrumb className="breadCrumb">
            <Breadcrumb.Item>Fixel</Breadcrumb.Item>
            <Breadcrumb.Item>Job</Breadcrumb.Item>
          </Breadcrumb>
          {content}
        </Content>

        {/*Footer*/}
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Meta Technology ©2023 Created by Meta
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Job;
